<template>
  <div>
    <div v-if="!users || (!users.length && !loading)">
      Geen gearchiveerde gebruikers gevonden
    </div>
    <sb-gql-table
      ref="table"
      :config="tableConfig"
      :query-options="queryOptions"
      data-path="users"
      @change="users = $event"
      @loading="loading = $event"
      @row-action-unarchive="unarchiveUsers([$event])"
      @row-action-delete="deleteArchivedUsers([$event])"
      @bulk-action-unarchive="unarchiveUsers"
      @bulk-action-delete="deleteArchivedUsers"
    >
      <template slot="filter-role" slot-scope="{ filterRef }">
        <i-select size="small" @input="filterRef.filterValue = $event">
          <i-option
            v-for="role in ['MANAGER', 'COACH', 'STUDENT']"
            :key="role"
            :value="role"
          >
            {{ getRoleLabel(role, true) }}
          </i-option>
        </i-select>
      </template>
    </sb-gql-table>
  </div>
</template>

<script>
import SbGqlTable from '@/components/SbTable2/SbGqlTable';
import ArchivedUsers_MANAGER from '@/graphql/queries/ArchivedUsers.manager.gql';
import ArchivedUsers_ADMIN from '@/graphql/queries/ArchivedUsers.admin.gql';
import MutationUnarchiveUsers from '@/graphql/mutations/UnarchiveUsers.gql';
import RemoveArchivedUsers from '@/graphql/mutations/RemoveArchivedUsers.gql';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import { TableConfig } from './SbTable2/TableConfig';
import { StringFilter } from '@/lib/gql-filters';
import { vmConfirm } from '@/lib/vm-confirm';
import { roleMixin } from '@/lib/role';

export default {
  components: {
    SbGqlTable,
  },

  mixins: [
    roleMixin,
    nonReactiveMembersMixin((self) => {
      const rowActions = [['unarchive', 'Herstellen']];
      const bulkActions = [['unarchive', 'Herstellen']];

      const columns = [
        {
          key: 'username',
          header: 'Gebruikersnaam',
          filter: 'search',
          sortable: true,
          meta: { gqlFilter: StringFilter },
        },
        {
          key: 'fullName',
          header: 'Naam',
          filter: 'search',
          sortable: true,
          meta: { gqlFilter: StringFilter },
        },
      ];

      if (self.$user.role === 'ADMIN') {
        bulkActions.push(['delete', 'Permanent verwijderen']);
        rowActions.push(['delete', 'Permanent verwijderen']);
      }

      if (
        self.$user.context.role === 'MANAGER' ||
        self.$user.context.resourceType === 'SCHOOL'
      ) {
        columns.push({
          key: 'role',
          header: 'Rol',
          filter: 'search',
          data: (row) => {
            console.log({ row });
            return row.organisations?.[0]?.role
              ? self.parseRole(row.organisations?.[0]?.role)
              : '';
          },
        });
      }

      const tableConfig = new TableConfig({
        rowActions,
        bulkActions,
        columns,
      });

      return { tableConfig };
    }),
  ],

  props: {
    organisationId: {
      type: String,
      default() {
        return this.$user.context.resourceId === 'default'
          ? undefined
          : this.$user.context.resourceId;
      },
    },
  },

  data: () => ({ users: [], loading: false }),

  methods: {
    queryOptions({
      first,
      last,
      after,
      before,
      filter,
      filters,
      orderBy,
      pagination,
    }) {
      console.log({ filters });
      const query =
        this.$user.role === 'MANAGER' ||
        this.$user.context.resourceType === 'SCHOOL'
          ? ArchivedUsers_MANAGER
          : ArchivedUsers_ADMIN;

      const variables = {
        first,
        last,
        after,
        before,
        orderBy,
        filter: {
          ...filter,
          role: filters.role?.value,
          archived: true,
          organisationId: this.organisationId
            ? { equals: this.organisationId }
            : undefined,
        },
      };

      return {
        query,
        variables,
      };
    },

    parseRole(role) {
      return {
        STUDENT: 'Leerling',
        COACH: 'Begeleider',
        MANAGER: 'Schoolbeheerder',
        SCHOOL_DISTRICT_MANAGER: 'Schooldistrict beheerder',
        PARTNER_MANAGER: 'Partner beheerder',
      }[role];
    },

    async unarchiveUsers(rows) {
      const userIds = rows.map((user) => user.id);

      try {
        const result = await this.$apollo.mutate({
          mutation: MutationUnarchiveUsers,
          variables: { userIds },
        });

        if (result.errors) {
          return this.$showGenericError();
        }

        this.$Modal.success({
          title: 'Gelukt',
          content: `De gebruiker${
            userIds.length > 1 ? 's zijn' : ' is'
          } succesvol teruggezet.`,
        });

        this.$refs.table.refresh();
        this.$refs.table.unselectAllRows();
      } catch (error) {
        console.error(error);
        this.$showGenericError();
      }
    },

    async deleteArchivedUsers(rows) {
      const userIds = rows.map((user) => user.id);

      if (
        !(await vmConfirm.call(
          this,
          'Weet je het zeker?',
          `Weet je absoluut zeker dat je deze gebruiker${
            userIds.length > 1 ? 's' : ''
          } wil verwijderen?`,
        ))
      ) {
        return;
      }

      try {
        const result = await this.$apollo.mutate({
          mutation: RemoveArchivedUsers,
          variables: { userIds },
        });

        if (result.errors) {
          return this.$showGenericError();
        }

        this.$Modal.success({
          title: 'Gelukt',
          content: `De gebruiker${
            userIds.length > 1 ? 's zijn' : ' is'
          } succesvol verwijderd.`,
        });

        this.$refs.table.refresh();
        this.$refs.table.unselectAllRows();
      } catch (error) {
        console.error(error);
        this.$showGenericError();
      }
    },
  },
};
</script>
