<template>
  <div>
    <!-- <Breadcrumb>
      <BreadcrumbItem>Partners</BreadcrumbItem>
    </Breadcrumb> -->

    <sb-page-header>
      <template v-slot:left>
        <h1>Gearchiveerde Gebruikers</h1>
      </template>
      <template v-slot:right> </template>
    </sb-page-header>

    <sb-call-out>
      <template slot="left"> ℹ️ </template>
      <template slot="right">
        Gearchiveerde gebruikers zijn ontkoppeld van scholen / cursusgroepen /
        educatiegroep etc. Nadat een gebruiker is teruggezet, zal het wachwoord
        opnieuw moeten worden ingesteld.
      </template>
    </sb-call-out>

    <sb-spacer height="20" />

    <sb-archived-users-table />

    <sb-spacer height="200" />
  </div>
</template>

<script>
import SbPageHeader from '@/components/SbPageHeader';
import SbArchivedUsersTable from '@/components/SbArchivedUsersTable';
import SbCallOut from '@/components/SbCallOut';

export default {
  components: {
    SbPageHeader,
    SbCallOut,
    SbArchivedUsersTable,
  },
};
</script>

<style lang="scss"></style>
